<template>
  <div class="page-requests">
    <n-loader :loading="$var('load')" />
    <top-bar back backroute="profile" :title="$app.secure.clean($n.t('10.5'))" is-auth />
    <div class="container">
      <div v-if="applications.length !== 0" class="orders-wrap">
        <div v-for="item in applications" :key="item.id" class="item"
             @click="$router.push({name: 'myApp', params: {id: item.id, type: 'inactive'}})">
          <div>
            <div class="title">{{ item.name }}</div>
            <div class="volume">{{ item.volume }} кг</div>
          </div>
          <div class="date">
            {{ $app.date.format(item.createdAt, 'datetime') }}
            <n-icon icon="right-arrow" />
          </div>
        </div>
      </div>
      <div v-else class="empty">
        <div class="image-place">
          <n-icon icon="empty" />
        </div>
        <div class="text">
          <t name="13.1" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import logo from 'assets/little-logo.png'
import img from 'assets/phone.png'

export default {
  name: 'PageRequests',
  data() {
    return {
      logo, img,
      applications: [],
      user: $app.auth.user(),
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.application.myAppRequests().then((response) => {
        this.applications = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.page-requests{
  .img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 180px;
    margin: 0 auto 8px;
  }
  .empty {
    min-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .orders-wrap {
    margin-top: 25px;
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #D9D9D9;
      padding-bottom: 12px;
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }

      .title {
        font-weight: 500;
      }

      .volume {
        font-weight: 400;
        font-size: 16px;
        color: #102E4F;
      }

      .date {
        font-weight: 400;
        font-size: 16px;
        color: #8C8C8C;

        .n-icon {
          margin-left: 16px;
        }
      }
    }
  }

  .title {
    font-weight: 400;
    font-size: 18px;
    color: #102E4F;
  }
}
</style>
